import React from "react"

const Section = ({ header = "no header set", children }) => {
  return (
    <section className="section">
      <div className="section__header">
        <h1>{header}</h1>
      </div>
      {children}
    </section>
  )
}

export default Section
