import React from "react"
import { Link } from "gatsby"
import logo from "../../images/vector/logo.svg"
import hamburger from "../../images/vector/hamburger.svg"

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar__branding">
        <Link to="/">
          <img src={logo} alt="TERSE DIGITAL" />
        </Link>
      </div>
      <img src={hamburger} alt="Hamburger" className="navbar__hamburger" />
      <ul className="navbar__links">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/404">Digital Marketing</Link>
        </li>
        <li>
          <Link to="/404">Articles</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
