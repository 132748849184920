import React from "react"
import triangle from "../../images/vector/to-top.svg"

const ToTop = () => {
  return (
    <div className="to-top">
      <img src={triangle} alt="To top" />
    </div>
  )
}

export default ToTop
