import React from "react"
import { Link } from "gatsby"

const NavMobile = () => {
  return (
    <div className="nav-mobile --hidden">
      <nav>
        <ul className="nav-mobile__links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">Digital Marketing</Link>
          </li>
          <li>
            <Link to="/">Articles</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default NavMobile
