import React from "react"
import { Helmet } from "react-helmet"
import Wrapper from "./Wrapper"
import ToTop from "../components/nav/ToTop"
import Navbar from "../components/nav/Navbar"
import NavMobile from "../components/nav/NavMobile"
import Footer from "../components/Footer"
import { setTitle } from "../helpers"
import "reset.css"
import "../styles/main.scss"

const Layout = ({ pageTitle, children }) => {
  const title = setTitle(pageTitle)

  return (
    <>
      <Helmet title={title} defer={false} htmlAttributes={{ lang: "en" }} />
      <ToTop />
      <NavMobile />
      <Wrapper>
        <Navbar />
      </Wrapper>
      {children}
      <Wrapper>
        <Footer />
      </Wrapper>
    </>
  )
}

export default Layout
