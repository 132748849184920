import React from "react"
import Wrapper from "../templates/Wrapper"
import "../styles/footer.scss"

const Footer = () => {
  const thisYear = new Date(Date.now()).getFullYear()

  return (
    <Wrapper>
      <footer className="footer">
        Copyright © {thisYear} Robin Edmunds <span>■</span> TERSE DIGITAL is the
        trading name of Robin Edmunds, acting as a sole trader in the UK.
      </footer>
    </Wrapper>
  )
}

export default Footer
